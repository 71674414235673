// import styled from 'styled-components';

// export const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   min-height: 100vh;
//   background-color: #f0f0f0;
//   padding: 2rem;
// `;

// export const Title = styled.h2`
//   font-size: 2rem;
//   font-weight: bold;
//   text-align: center;
//   margin-bottom: 2rem;
// `;

// export const ServicesList = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//   max-width: 800px;
// `;

// export const Service = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   padding: 1.5rem;
//   background-color: #fff;
//   border-radius: 5px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   margin-bottom: 2rem;
// `;

// export const ServiceTitle = styled.h3`
//   font-size: 1.5rem;
//   font-weight: bold;
//   text-align: center;
//   margin-bottom: 1rem;
// `;

// export const ServiceDescription = styled.p`
//   font-size: 1.1rem;
//   line-height: 1.6;
//   text-align: center;
// `;

import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #444;
  padding: 2rem;
  min-height: 65vh
`;



export const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  color: #fff;

`;

export const ServicesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
`;

export const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
  animation: ${fadeIn} 1s ease-out;
  transition: transform 0.3s;

  border-width: 0.5px;
  border-style: solid;
  border-color: #000080;

  &:hover {
    transform: translateY(-10px);
  }
`;

export const ServiceIcon = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: center;
`;

export const ServiceDescription = styled.p`
  font-size: 1rem;
  text-align: center;
  line-height: 1.5;
`;

export const PNGIcon = styled.img`
  width: 7.5rem;
  height: auto;
`;
