import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  padding: 1rem 2rem;
  color: #fff;
`;

//Jous version
// export const PoweredBy = styled.div`
//   display: flex;
//   align-items: center;
//   font-size: 1rem;
// `;

export const PoweredBy = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  white-space: nowrap;
`;

export const Logo = styled.img`
  height: 0.8rem;
  margin-left: 0.5rem;
`;

export const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
`;

export const SocialLink = styled.a`
  font-size: 1.5rem;
  color: #fff;
  transition: all 0.3s;

  &:hover {
    color: #ccc;
  }
`;
