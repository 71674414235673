// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React from 'react';
import './App.css';
import { Navbar } from './components/Navbar/Navbar';
import { Description, HomePage } from './components/HomePage/HomePage';
import { Services } from './components/Services/Services';
import { Team } from './components/Team/Team';
import { Contact } from './components/ContactForm/Contact';
import { Footer } from './components/Footer/Footer';
import { Info } from './components/Info/Info';
import { Element } from 'react-scroll';
import { Clients } from './components/Clients/Clients';
import {Modal} from './layoutDesigns/Modal'

function App() {
  return (
    <div className="App">
      <Navbar />
      <Element name="home">
        <HomePage />
        <Description />
      </Element>
      <Element name="services">
        <Services />
      </Element>
      <Element name="info">       
          <Info />
      </Element>
      <Element name="team">
        <Team />
      </Element>
      <Element name="contact">
        <Contact/>
      </Element>
      {/* <Element name="clients">
        <Clients />
      </Element> */}
      <Footer />
      
      {/* Example of how to use the split screen
      <Element name="info">       
          <SplitScreen leftWeight={2} rightWeight={1}>
            <Info />
            <Info />
          </SplitScreen>
      </Element> */}
            
      {/* Example of how to use the Modal */}
      {/* <Element name="info">       
        <Modal>
        <Info />
        </Modal>
      </Element> */}

    </div>
  );
}

export default App;

