import styled from 'styled-components';

//Jous version
// export const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   min-height: 100vh;
//   background-color: #fff;
//   padding: 2rem;
// `;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  background-color: #fff;
`;

// export const Block = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 1.5rem;
//   background-color: #fff;
//   border: 2px solid black;
//   border-radius: 5px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   width: 100%;
//   max-width: 600px;
//   margin: 50px 0;
// `;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 56vh;
`;

export const Box = styled.div`
  padding: 0.9rem;
  background-color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: #000080;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  min-height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem;
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  color: #000080;
`;

export const Text = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 1rem;

`;

export const AboutContent = styled.div`
  max-width: 800px;
  text-align: center;

  p {
    font-size: 1.1rem;
    color: #000;
    line-height: 1.5;
  }
`;