import styled from 'styled-components';
import { Link } from 'react-scroll';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  background-color: #000;
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const Logo = styled.img`
  max-width: 85%;
  height: 2.5rem;
  cursor: pointer;
`;

// export const NavMenu = styled.ul`
//   display: flex;
//   list-style: none;
//   margin: 0;
//   padding: 0;

//   @media (max-width: 768px) {
//     flex-wrap: wrap;
//     width: 100%;
//     justify-content: space-evenly;
//     padding-top: 1rem;
//   }
// `;

export const NavMenu = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    display: ${({ menuOpen }) => (menuOpen ? 'flex' : 'none')};
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 70px;
    left: 0;
    background-color: #000;
    z-index: 1;
  }
`;

export const NavMenuItem = styled.li`
  margin: 0 1rem;

  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;

export const NavLink = styled(Link)`
  cursor: pointer;
  font-size: 1rem;
  color: #fff;
  margin: 0 1rem;
  transition: color 0.3s;

  &:hover {
    color: #ccc;
  }
`;

export const MobileMenuIcon = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  div {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 4px;
    transition: all 0.3s ease;

    &:nth-child(1) {
      transform: ${({ menuOpen }) => (menuOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      opacity: ${({ menuOpen }) => (menuOpen ? '0' : '1')};
    }

    &:nth-child(3) {
      transform: ${({ menuOpen }) => (menuOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;