// import React from 'react';
// import { Link } from 'react-scroll';
// import { Container, Nav, Logo, NavLink } from './Navbar.styles';
// import logo from '../../assets/logos/company-logo2.png';

// export const Navbar = () => {
//   return (
//     <Container>
//       <Nav>
//         <Logo src={logo} alt="Gambit Tech Solutions" />
//         <NavLink to="home" smooth={true} duration={500}>
//           Home
//         </NavLink>
//         <NavLink to="services" smooth={true} duration={500}>
//           Services
//         </NavLink>
//         <NavLink to="team" smooth={true} duration={500}>
//           Team
//         </NavLink>
//         <NavLink to="contact" smooth={true} duration={500}>
//           Contact Us
//         </NavLink>
//       </Nav>
//     </Container>
//   );
// };

// import React from 'react';
// import { Link } from 'react-scroll';
// import { Container, Nav, Logo, NavLink, NavMenu, NavMenuItem } from './Navbar.styles';
// import logo from '../../assets/logos/company-logo2.png';

// export const Navbar = () => {
//   return (
//     <Container>
//       <Nav>
//         <Logo src={logo} alt="Gambit Tech Solutions" />
//         <NavMenu>
//           <NavMenuItem>
//             <NavLink to="home" smooth={true} duration={500}>
//               Home
//             </NavLink>
//           </NavMenuItem>
//           <NavMenuItem>
//             <NavLink to="services" smooth={true} duration={500}>
//               Services
//             </NavLink>
//           </NavMenuItem>
//           <NavMenuItem>
//             <NavLink to="team" smooth={true} duration={500}>
//               Team
//             </NavLink>
//           </NavMenuItem>
//           <NavMenuItem>
//             <NavLink to="contact" smooth={true} duration={500}>
//               Contact Us
//             </NavLink>
//           </NavMenuItem>
//         </NavMenu>
//       </Nav>
//     </Container>
//   );
// };

import React, { useState } from 'react';
import { Container, Nav, Logo, NavLink, NavMenu, NavMenuItem, MobileMenuIcon } from './Navbar.styles';
import logo from '../../assets/logos/gambitts_logo.png';

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Container>
      <Nav>
        <Logo src={logo} alt="Gambit Tech Solutions" />
        <MobileMenuIcon onClick={handleMenuToggle} menuOpen={menuOpen}>
          <div />
          <div />
          <div />
        </MobileMenuIcon>
        <NavMenu menuOpen={menuOpen}>
          <NavMenuItem>
            <NavLink to="home" smooth={true} duration={500} offset={0} onClick={() => setMenuOpen(false)}>
              Home
            </NavLink>
          </NavMenuItem>
          <NavMenuItem>
            <NavLink to="services" smooth={true} duration={500} offset={-72} onClick={() => setMenuOpen(false)}>
              Services
            </NavLink>
          </NavMenuItem>
          <NavMenuItem>
            <NavLink to="team" smooth={true} duration={500} offset={-72} onClick={() => setMenuOpen(false)}>
              Team
            </NavLink>
          </NavMenuItem>
          <NavMenuItem>
            <NavLink to="contact" smooth={true} duration={500} offset={-72} onClick={() => setMenuOpen(false)}>
              Contact Us
            </NavLink>
          </NavMenuItem>
        </NavMenu>
      </Nav>
    </Container>
  );
};
