import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center; /* added this line */

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Pane = styled.div`
  flex: ${props => props.weight};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const SplitScreen = ({
    children,
    leftWeight = 1,
    rightWeight = 1,
}) => {
    const [left, right] = children
    return(
        <Container> 
            <Pane weight={leftWeight}>
                {left}
            </Pane>
            <Pane weight={rightWeight}>
                {right}
            </Pane>
        </Container>
    )
}