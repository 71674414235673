import React from 'react';
import { Container, PoweredBy, SocialLinks, SocialLink, Logo } from './Footer.styles';
import logo from '../../assets/logos/gambitts_logo.png';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';

export const Footer = () => {
  return (
    <Container>
      <PoweredBy>
        Powered By <Logo src={logo} alt="Gambit Tech Solutions" />
      </PoweredBy>
      <SocialLinks>
        <SocialLink href="https://www.instagram.com/gambitt.s/" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </SocialLink>
        {/* <SocialLink href="https://www.linkedin.com/company/gambit-tech-solutions" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </SocialLink> */}
      </SocialLinks>
    </Container>
  );
};
