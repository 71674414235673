import React from 'react';
import { Container, Content, Title, Text ,AboutContent, Emblema, EmblemaContainer, Box, Block} from './Info.styles';
import { SplitScreen } from '../../layoutDesigns/SplitScreen';
import { Clients } from '../Clients/Clients';

import { Slider } from '../../layoutDesigns/Slider'



// export const Info = () => {
//     return (
//       <Container>
//         <Content>
//         <Box>
//             <Title>Our Goal</Title>
//             <AboutContent>
//               <p>Our goal is to provide innovative and effective solutions to our clients, leveraging the latest advancements in technology and ensuring their success in the digital era.</p>
//             </AboutContent>
//           </Box>
//           <SplitScreen rightWeight={1.5}>
//             <EmblemaContainer>
//               <Emblema src={emblema} />
//             </EmblemaContainer>
//             <Block>
//             <Box>
//             <Title>Why Choose us?</Title>
//             <AboutContent>
//               <p>Gambitt Solutions offers top-notch consulting services for tech problems. Our experts provide innovative solutions, keeping clients ahead of trends. We're a trusted partner with industry expertise, focused on customer satisfaction. Choose us to take your business to new heights.</p>
//             </AboutContent>
//             </Box>
//             </Block>
//           </SplitScreen>
//         </Content>
//       </Container>
//     );
//   };


// export const Info = () => {
//   return (
//     <Container>
//       <Content>
      
//         <SplitScreen rightWeight={3}>
//           <EmblemaContainer>
//             <Emblema src={emblema} />
//           </EmblemaContainer>
//           <Slider slides={[<OurGoal />, <ChooseUs />]}/>
          
//         </SplitScreen>
//       </Content>
//     </Container>
//   );
// };

export const Info = () => {
  return (
    <Container>
      <Content>
        <SplitScreen leftWeight={10} rightWeight={1}>
          <Slider slides={[<ChooseUs />, <OurGoal />]} isAutoSlider={true} sliderSecChange={45} useFade={false} />
          <Clients />
        </SplitScreen>
      </Content>
    </Container>
  );
};



const OurGoal = () => {
  return(
    <Block>
      <Box>
        <Title>Our Goal</Title>
        <AboutContent>
          <p>Our goal is to provide innovative and effective solutions to our clients, leveraging the latest advancements in technology and ensuring their success in the digital era.</p>
        </AboutContent>
      </Box>
    </Block>
  )
};

const ChooseUs = () => {
  return (
    <Block>
      <Box>
        <Title>Why Choose Us?</Title>
        <AboutContent>
          <p>Gambitt Solutions offers top-notch consulting services for tech problems. Our experts provide innovative solutions, keeping clients ahead of trends. We're a trusted partner with industry expertise, focused on customer satisfaction. Choose us to take your business to new heights.</p>
        </AboutContent>
      </Box>
    </Block>
  )
};
