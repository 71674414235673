import styled from 'styled-components';

//Jous version
// export const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   min-height: 100vh;
//   background-color: #fff;
//   padding: 2rem;
// `;

//Victor removed min-height: 100vh;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

// export const Block = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 1.5rem;
//   background-color: #fff;
//   border: 2px solid black;
//   border-radius: 5px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   width: 100%;
//   max-width: 600px;
//   margin: 50px 0;
// `;


export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-bottom: 0.5rem;
`;

export const Logo = styled.img`
  width: 600px;
  max-width: 90%;
  height: auto;
  margin-bottom: 2rem;
  padding-top: 7.5rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem;
`;

//Jous version
// export const Content = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//   max-width: 800px;
// `;

export const Title = styled.h1`
  font-size: 2.25rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
`;

export const Text = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 1rem;

`;

export const AboutContent = styled.div`
  max-width: 800px;
  margin: 0.8rem;
  text-align: center;

  p {
    font-size: 1.2rem;
    color: #000;
    line-height: 1.4;
    // margin-bottom: 1rem;
  }
`;

export const EmblemaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const Emblema = styled.img`
  width: 300px;
  height: 300px;
  display: flex;
`;