// import React from 'react';
// import { Container, Title, ServicesList, Service, ServiceTitle, ServiceDescription } from './Services.styles';

// export const Services = () => {
//   return (
//     <Container>
//       <Title>Our Services</Title>
//       <ServicesList>
//         <Service>
//           <ServiceTitle>Website Creation</ServiceTitle>
//           <ServiceDescription>
//             We design and develop modern, responsive websites tailored to your business needs.
//           </ServiceDescription>
//         </Service>
//         <Service>
//           <ServiceTitle>Powerful Excel Scripts</ServiceTitle>
//           <ServiceDescription>
//             Optimize your workflow with custom Excel scripts that automate tasks and analyze data efficiently.
//           </ServiceDescription>
//         </Service>
//         <Service>
//           <ServiceTitle>App Creation</ServiceTitle>
//           <ServiceDescription>
//             Bring your app ideas to life with our custom app development services for web, mobile, and desktop platforms.
//           </ServiceDescription>
//         </Service>
//       </ServicesList>
//     </Container>
//   );
// };

import React from 'react';
import { Container, Title, ServicesList, ServiceCard, ServiceIcon, ServiceTitle, ServiceDescription, PNGIcon } from './Services.styles';
import { ReactComponent as WebIcon } from '../../assets/icons/web-icon.svg'; // Replace with your own SVG icon
import { ReactComponent as ExcelIcon } from '../../assets/icons/excel-icon.svg'; // Replace with your own SVG icon
import { ReactComponent as AppIcon } from '../../assets/icons/app-icon.svg'; // Replace with your own SVG icon
import pbiIcon from '../../assets/icons/pbiicon.png';
import problemSolving from '../../assets/icons/problemsolving.png';

export const Services = () => {
  return (
    <Container>
      <Title>Our Services</Title>
      <ServicesList>
        <ServiceCard>
          <ServiceIcon>
            <WebIcon />
          </ServiceIcon>
          <ServiceTitle>Website Creation/Maintenance</ServiceTitle>
          <ServiceDescription>
            We provide comprehensive website creation and maintenance services, ensuring your online presence is always up-to-date, secure, and engaging.           
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon>
            <PNGIcon src={pbiIcon} />
          </ServiceIcon>
          <ServiceTitle>Data Visualization Tool Creation</ServiceTitle>
          <ServiceDescription>
            Construct data analytics dashboards with tools such as Power BI and Tableau for your company.
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon>
            <ExcelIcon />
          </ServiceIcon>
          <ServiceTitle>Powerful Excel Scripts</ServiceTitle>
          <ServiceDescription>
            Our team can develop powerful Excel scripts to help you automate tasks and analyze your data efficiently.
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon>
            <AppIcon />
          </ServiceIcon>
          <ServiceTitle>App Creation</ServiceTitle>
          <ServiceDescription>
            We design and develop custom mobile and web applications to help your business grow and succeed in the digital world.
          </ServiceDescription>
        </ServiceCard>
        {/* <ServiceCard>
          <ServiceIcon>
            <PNGIcon src={problemSolving} />
          </ServiceIcon>
          <ServiceTitle>IT Problem Solving</ServiceTitle>
          <ServiceDescription>
          We tackle a wide range of technology challenges for your business, including system integration, cloud migration, domain administration, and data analytics.</ServiceDescription>
        </ServiceCard> */}
      </ServicesList>
    </Container>
  );
};



