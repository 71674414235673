// import React from 'react';
// import { Container, LogoContainer, Logo, Content, Title, Text ,AboutContent, Block, MyList, Box} from './HomePage.styles';
// // import logo from '../../assets/logo/company-logo.png'; // Replace this with the path to your logo image file
// import logo from '../../assets/logos/complete_logo.png';
// import backDesign from '../../assets/logos/background_design.png';


// export const HomePage = () => {
//   return (
//     <Container>
//       <LogoContainer style={{ backgroundImage:`url(${backDesign})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
//         <Logo src={logo} alt="Gambit Tech Solutions"/>
//       </LogoContainer>
//       <Content>
        
//         <Title>Welcome to Gambitt Solutions</Title>
//         <AboutContent>
//           <p>Gambitt Solutions is a leading consulting company dedicated to providing top-notch technology services to its clients. With a team of highly skilled professionals, Gambitt Solutions is committed to delivering the best results to its clients. The company offers a wide range of services, including software development, data analysis, and IT consulting. The goal is to help businesses of all sizes to harness the power of technology and improve their bottom line. With a focus on innovation and quality, we have established a reputation for excellence in the industry. Gambitt Solutions has the expertise and resources to help you achieve your goals and succeed in the ever-evolving world of technology.</p>
//         </AboutContent>

//         <MyList>
//           <Block>
//             <Box>
//               <Title>Our Goal</Title>
//               <AboutContent>
//                 <p>Our goal is to provide innovative and effective solutions to our clients, leveraging the latest advancements in technology and ensuring their success in the digital era.</p>
//               </AboutContent>
//             </Box>
//           </Block>
//           <Block>
//             <Box>
//             <Title>Why Choose us?</Title>
//             <AboutContent>
//               <p>Gambitt Solutions offers top-notch consulting services for tech problems. Our experts provide innovative solutions, keeping clients ahead of trends. We're a trusted partner with industry expertise, focused on customer satisfaction. Choose us to take your business to new heights.</p>
//             </AboutContent>
//             </Box>
//           </Block>
//         </MyList>
//       </Content>
//     </Container>
//   );
// };

import React from 'react';
import { Container, LogoContainer, Logo, Content, Title, Text ,AboutContent, Emblema, EmblemaContainer} from './HomePage.styles';
import { SplitScreen } from '../../layoutDesigns/SplitScreen';
// import logo from '../../assets/logo/company-logo.png'; // Replace this with the path to your logo image file
import logo from '../../assets/logos/complete_logo.png';
import backDesign from '../../assets/logos/background_design.png';
import emblema from '../../assets/logos/emblema-no-background.png';

export const HomePage = () => {
  return (
    <Container>
      <LogoContainer style={{ backgroundImage:`url(${backDesign})`,backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
        <Logo src={logo} alt="Gambit Tech Solutions"/>
      </LogoContainer>
    </Container>
  );
};

export const Description = () => {
  return (
    <Container>
      <Content>
      <Title>Welcome to Gambitt Solutions</Title>
        <div>
          <SplitScreen leftWeight={3} rightWeight={2}>
            <AboutContent>
              <p>Gambitt Solutions, a leading consulting firm, provides top-tier technology services with a team of highly skilled professionals. We specialize in software development, data analysis, and IT consulting, catering to businesses of all sizes. Our focus on innovation and quality has earned us industry-wide excellence. Trust Gambitt Solutions to propel your success in the rapidly evolving technology landscape.</p>
            </AboutContent>
          <EmblemaContainer>
            <Emblema src={emblema} />
          </EmblemaContainer>
          </SplitScreen>
        </div>
      </Content>
    </Container>
  );
};


