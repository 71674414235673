import React from 'react';
import { Container, Title, ClientLogo, LogoSliderContainer } from './Clients.styles';
import todo2go from '../../assets/images/ClientLogos/todo2go.png';
import perfectpacking from '../../assets/images/ClientLogos/perfectpacking.png';
import bcreative from '../../assets/images/ClientLogos/bcreative.png';
import constructoraEsco from '../../assets/images/ClientLogos/constructoraesco.png';
import pbg from '../../assets/images/ClientLogos/pbg.png'
import { Slider } from '../../layoutDesigns/Slider';

// export const Clients = () => {
//   return (
//     <Container>
//       <Title>Clients</Title>
//       <ServicesList>
//         <ClientCard>
//           <ClientLogo src={todo2go} />
//         </ClientCard>
//         <ClientCard>
//           <ClientLogo src={perfectpacking} />
//         </ClientCard>
//         <ClientCard>
//           <ClientLogo src={bcreative} />
//         </ClientCard>
//       </ServicesList>
//     </Container>
//   );
// };

const slides = [<ClientLogo src={todo2go} />, 
                <ClientLogo src={perfectpacking} />, 
                <ClientLogo src={bcreative} />, 
                <ClientLogo src={pbg} />, 
                <ClientLogo src={constructoraEsco} /> ]

export const Clients = () => {
  return (
    <Container>
      <Title>Clients</Title>
        <LogoSliderContainer>
          <Slider slides={slides} isAutoSlider={true} ShowDots={false} showArrows={false} sliderSecChange={6}/>
        </LogoSliderContainer>
    </Container>
  );
};


