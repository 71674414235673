import { is } from "@babel/types";
import { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";


const fadeIn = keyframes`
    0% {
        transform: translateX(-20%);
        opacity: 0;
    }
    10% {
        transform: translateX(0%);
        opacity: 1;
    }
    90% {
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        transform: translateX(20%);
        opacity: 0;
    }
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  animation: ${props => props.useFade ? css`${fadeIn} ${props.sliderSecChange}s ease-in-out` : ''};
 
  img {
    max-width: 20rem;
    max-height: 60rem;
  }
`;



const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 2rem;
    //min-height: 20rem;
    height: 100%;
    margin: 0 auto;
`;

const SlideContainer = styled.div`
    position: relative;
    height: 100%;
    width: 90%;
    background-color: #fff;
    flex 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;


const LeftArrow = styled.div`
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: -70px;
    font-size: 45px;
    color: #000080;
    z-index: 1;
    cursor: pointer;
`;

const RightArrow = styled.div`
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: -70px;
    font-size: 45px;
    color: #000080;
    z-index: 1;
    cursor: pointer;
`;

const SliderDots = styled.div`
    margin: 3px;
    cursor: pointer;
    font-size: 35px;
    color: ${props => props.isActive ? '#FFFEFE' : '#000080'};
    -webkit-text-stroke: 2px #000080;
`;


const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;




export const Slider = ({ slides, showArrows = true, ShowDots = true, sliderSecChange, isAutoSlider = true, useFade = true}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        let intervalId;
        if (isAutoSlider) {
            intervalId = setInterval(() => {
                goToNext();
            }, 1000 * sliderSecChange);
        }
        return () => clearInterval(intervalId);
    }, [currentIndex, isAutoSlider]);

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex -1;
        setCurrentIndex(newIndex);
    };
    
    const goToNext = () => {
        const isLAstSlide = currentIndex === slides.length - 1;
        const newIndex = isLAstSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = slideIndex => {
        setCurrentIndex(slideIndex);
    };

    return(
        <Container>
            <SlideContainer>
            {showArrows && (
                <>
                <LeftArrow onClick={goToPrevious}>〈</LeftArrow>
                <RightArrow onClick={goToNext}>〉</RightArrow>
                </>
            )}
                
                <Slide key={currentIndex} sliderSecChange={sliderSecChange + 0.1} useFade={useFade}>    
                        {slides[currentIndex]}
                </Slide>
            </SlideContainer>
            {ShowDots && (
                <>
                <DotContainer>
                        {slides.map((slide, slideIndex) => (
                            <SliderDots key={slideIndex} 
                                        isActive={slideIndex === currentIndex} 
                                        onClick={() => goToSlide(slideIndex)}>
                                •
                            </SliderDots>
                        ))}
                </DotContainer>
                </>
            )}
        </Container>
    );
};


        //Example How to implement it. It should work with any type of component
// import { Slider } from '../../layoutDesigns/Slider'

// import image1 from '../../assets/images/ClientLogos/bcreative.png'
// import image2 from '../../assets/images/ClientLogos/perfectpacking.png'
// import image3 from '../../assets/images/ClientLogos/todo2go.png'

// export const Sliders = () => {
            //In here you would put the components u want to appear in the slider
//   const slides = [<img src={image1}  />, <img src={image2}  />, <img src={image3}  />]

//   return (
//     <Slider slides={slides}/>
//   );
// };