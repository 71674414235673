// import React from 'react';
// import { Container, Title, TeamList, TeamMember, MemberImage, MemberName, MemberDescription } from './Team.styles';
// // import member1 from '../../assets/member1.jpg'; // Replace this with the path to your image file
// // import member2 from '../../assets/member2.jpg'; // Replace this with the path to your image file
// import member1 from '../../assets/images/member1.jpg';
// import member2 from '../../assets/images/member2.jpg';

// export const Team = () => {
//   return (
//     <Container>
//       <Title>Meet the Team</Title>
//       <TeamList>
//         <TeamMember>
//           <MemberImage src={member1} alt="Member 1" />
//           <MemberName>Victor Guttman</MemberName>
//           <MemberDescription>
//             Victor the Excel Expert.
//           </MemberDescription>
//         </TeamMember>
//         <TeamMember>
//           <MemberImage src={member2} alt="Member 2" />
//           <MemberName>Joseph Bensabat</MemberName>
//           <MemberDescription>
//             Joseph the Software Engineer
//           </MemberDescription>
//         </TeamMember>
//       </TeamList>
//     </Container>
//   );
// };

import React from 'react';
import { Container, Title, TeamMembers, MemberCard, MemberImage, MemberName, MemberDescription,TeamContent } from './Team.styles';
import member1 from '../../assets/images/member1.jpg';
import member2 from '../../assets/images/member2.jpg';

export const Team = () => {
  return (
    <Container>
      <Title>About the Team</Title>
      <TeamContent>
      <p>Joseph and Victor are both proud engineering graduates from the University of Florida. With extensive backgrounds in software and industrial engineering, they have honed their skills at major corporations such as Procter & Gamble. Their combined expertise and shared passion for technology led them to establish Gambitts, where they continue to innovate and deliver cutting-edge solutions for businesses.</p>
      </TeamContent>
      <TeamMembers>
        <MemberCard>
          <MemberImage src={member1} alt="Member 1" />
          <MemberName>Victor</MemberName>
          <MemberDescription>Victor brings his expertise in industrial engineering to the table, focusing on streamlining processes and optimizing systems for maximum efficiency. With a strong background in project management and a knack for finding innovative solutions to complex problems, Victor ensures that every project at Gambitts is executed with precision and professionalism.</MemberDescription>
        </MemberCard>
        <MemberCard>
          <MemberImage src={member2} alt="Member 2" />
          <MemberName>Joseph</MemberName>
          <MemberDescription>Joseph specializes in software development, with a keen eye for creating efficient and user-friendly applications. His experience working with global teams has equipped him with the necessary skills to manage projects of varying complexities and deliver excellent results for clients.</MemberDescription>
        </MemberCard>
      </TeamMembers>
    </Container>
  );
};

