// import React from 'react';
// import { send } from 'emailjs-com'
// import { useState } from 'react';
// import { Container, Title, Form, Input, TextArea, Button } from './Contact.styles';
// import {Modal} from '../../layoutDesigns/Modal';

// export const Contact = () => {
//   const [toSend, setToSend] = useState({
//     firstName: '',
//     lastName: '',
//     companyName: '',
//     phoneNumber: '',
//     email: '',
//     description: '',
//   });

//   const[submitted, setSubmitted] = useState(true);

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     send(
//       'service_4m20jyn',
//       'template_9pf2h7u',
//       toSend,
//       'jd_H-KJs82Wi413-E'
//     )
//       .then((response) => {
//         console.log('SUCCESS!', response.status, response.text);
//         setSubmitted(true)
//       })
//       .catch((err) => {
//         console.log('FAILED...', err);
//       });

//       // reset form
//   };

//   const handleChange = (e) => {
//     setToSend({ ...toSend, [e.target.name]: e.target.value });
//   };

//   return (
//     <Container>
//       <Title>Contact Us</Title>
//       <Form onSubmit={handleSubmit}>
//         <Input
//           type="text"
//           name="firstName"
//           placeholder="First Name"
//           onChange={handleChange}
//           required
//         />
//         <Input
//           type="text"
//           name="lastName"
//           placeholder="Last Name"
//           onChange={handleChange}
//           required
//         />
//         <Input
//           type="text"
//           name="companyName"
//           placeholder="Company Name"
//           onChange={handleChange}
//           required
//         />
//         <Input
//           type="tel"
//           name="phoneNumber"
//           placeholder="Phone Number"
//           onChange={handleChange}
//           required
//         />
//         <Input
//           type="email"
//           name="email"
//           placeholder="Email"
//           onChange={handleChange}
//           required
//         />
//         <TextArea
//           name="description"
//           placeholder="Describe the services you need"
//           onChange={handleChange}
//           required
//         ></TextArea>
//         <Button type="submit">Submit</Button>
//         {submitted && <Modal setShouldShow={true}>
//           <h2>Form Submitted Successfully</h2>
//           <p>Your form has been submitted. We will get back to you soon!</p>
//         </Modal>}
//       </Form>
//     </Container>
//   );
// };

// import React, { useState } from 'react';
// import { send } from 'emailjs-com';
// import { Container, Title, Form, Input, TextArea, Button } from './Contact.styles';
// import {Modal} from '../../layoutDesigns/Modal';
// import { ReactComponent as MailIcon } from '../../assets/icons/mail-icon.svg'; // Replace with your own SVG icon


// export const Contact = () => {
//   const [toSend, setToSend] = useState({
//     firstName: '',
//     lastName: '',
//     companyName: '',
//     phoneNumber: '',
//     email: '',
//     description: '',
//   });

//   const [formSubmitted, setFormSubmitted] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await send(
//         'service_4m20jyn',
//         'template_9pf2h7u',
//         toSend,
//         'jd_H-KJs82Wi413-E'
//       );
//       console.log('SUCCESS!', response.status, response.text);
//       setFormSubmitted(true);
//     } catch (err) {
//       console.log('FAILED...', err);
//     }
//   };

//   const handleChange = (e) => {
//     setToSend({ ...toSend, [e.target.name]: e.target.value });
//   };

//   const closeModal = () => {
//     if (formSubmitted) {
//       setToSend({
//         firstName: '',
//         lastName: '',
//         companyName: '',
//         phoneNumber: '',
//         email: '',
//         description: '',
//       });
//       setFormSubmitted(false);
//     }
//   };

//   return (
//     <Container>
//       <Title>Contact Us</Title>
//       <Form onSubmit={handleSubmit}>
//         <Input
//             type="text"
//             name="firstName"
//             placeholder="First Name"
//             onChange={handleChange}
//             required
//           />
//           <Input
//             type="text"
//             name="lastName"
//             placeholder="Last Name"
//             onChange={handleChange}
//             required
//           />
//           <Input
//             type="text"
//             name="companyName"
//             placeholder="Company Name"
//             onChange={handleChange}
//             required
//           />
//           <Input
//             type="tel"
//             name="phoneNumber"
//             placeholder="Phone Number"
//             onChange={handleChange}
//             required
//           />
//           <Input
//             type="email"
//             name="email"
//             placeholder="Email"
//             onChange={handleChange}
//             required
//           />
//           <TextArea
//             name="description"
//             placeholder="Describe the services you need"
//             onChange={handleChange}
//             required
//           ></TextArea>
//           <Button type="submit">Submit</Button>
//       </Form>
//       <Modal isOpen={formSubmitted} onClose={closeModal}>
//         <h2>Contact Request Sent</h2>
//         <MailIcon>
//         </MailIcon>
//         <p>We have received your contact request. We will get back to you soon!</p>
//       </Modal>
//     </Container>
//   );
// };


import React, { useState, useEffect} from 'react';
import { send } from 'emailjs-com';
import { Container, Title, Form, Input, TextArea, Button, MailIconStyle } from './Contact.styles';
import {Modal} from '../../layoutDesigns/Modal';
// import { ReactComponent as MailIcon } from '../../assets/icons/mail-icon.svg'; // Replace with your own SVG icon

export const Contact = () => {
  const [toSend, setToSend] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    phoneNumber: '',
    email: '',
    description: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await send(
        'service_4m20jyn',
        'template_9pf2h7u',
        toSend,
        'jd_H-KJs82Wi413-E'
      );
      console.log('SUCCESS!', response.status, response.text);
      setFormSubmitted(true);
    } catch (err) {
      console.log('FAILED...', err);
    }
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const closeModal = () => {
    if (formSubmitted) {
      setToSend({
        firstName: '',
        lastName: '',
        companyName: '',
        phoneNumber: '',
        email: '',
        description: '',
      });
      setFormSubmitted(false);
    }
  };

  // Fucntion to close the modal after X seconds
  useEffect(() => {
    let timeoutId;
    if (formSubmitted) {
      timeoutId = setTimeout(() => {
        closeModal();
      }, 5000); //number of seconds * 1000
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [formSubmitted]);

  return (
    <Container>
      <Title>Contact Us</Title>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="firstName"
          placeholder="First Name"
          onChange={handleChange}
          value={toSend.firstName}
          required
        />
        <Input
          type="text"
          name="lastName"
          placeholder="Last Name"
          onChange={handleChange}
          value={toSend.lastName}
          required
        />
        <Input
          type="text"
          name="companyName"
          placeholder="Company Name"
          onChange={handleChange}
          value={toSend.companyName}
          required
        />
        <Input
          type="tel"
          name="phoneNumber"
          placeholder="Phone Number"
          onChange={handleChange}
          value={toSend.phoneNumber}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Email"
          onChange={handleChange}
          value={toSend.email}
          required
        />
        <TextArea
          name="description"
          placeholder="Describe the services you need"
          onChange={handleChange}
          value={toSend.description}
          required
        ></TextArea>
        <Button type="submit">Submit</Button>
      </Form>
      <Modal isOpen={formSubmitted} onClose={closeModal}>
        <h2>Contact Request Sent</h2>
        <MailIconStyle />
        <p>We have received your contact request. We will get back to you soon!</p>
      </Modal>
    </Container>
  );
};
