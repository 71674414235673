// import { useState } from "react";
// import styled from "styled-components";

// const ModalBackground = styled.div`
//     position: fixed;
//     z-index: 1;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     overflow: auto;
//     background-color: rgba(0,0,0,0.5);
// `;

// const ModalBody = styled.div`
//   background-color: white;
//   margin: 10% auto;
//   padding: 20px;
//   width: 80%;

//   @media only screen and (max-width: 768px) {
//     margin: 30% auto;
//   }
// `;

// export const Modal =({children}) => {
//     const [shouldShow, setShouldShow] = useState(false);

//     return (
//         <>
//             <button onClick={() => setShouldShow(true)}> Learn More </button>
//             {shouldShow &&
//             <ModalBackground onClick={() => setShouldShow(false)}>
//                 <ModalBody onClick={e => e.stopPropagation()}>
//                     <button onClick={() => setShouldShow(false)}> Close </button>
//                     {children}
//                 </ModalBody>
//             </ModalBackground>
//             }
//         </>
//     )

// }


import { useState } from "react";
import styled from "styled-components";

const ModalBackground = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
`;

// const ModalBody = styled.div`
//   background-color: white;
//   margin: 15% auto;
//   padding: 20px;
//   width: 50%;
//   max-width: 400px;
//   position: relative;
//   border-radius: 10px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   @media only screen and (max-width: 768px) {
//     margin: 30% auto;
//     width: 80%;
//   }
// `;

const ModalBody = styled.div`
  background-color: white;
  margin: auto;
  padding: 20px;
  width: 50%;
  max-width: 400px;
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);

  @media only screen and (max-width: 768px) {
    margin: auto;
    width: 80%;
  }
`;


const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

export const Modal = ({ children, isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <ModalBackground onClick={onClose}>
      <ModalBody onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        {children}
      </ModalBody>
    </ModalBackground>
  );
};
